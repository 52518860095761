<template>
	<div class="change-resume">
		<div class="action-buttons">
			<div class="input-field file">
				<label for="file-upload" class="custom-file-upload">
					{{ fileName }}
					<input id="file-upload" type="file" @change="onFileChange" required/>
				</label>
				<i @click="uploadFile(fileToUpload)" class="fa fa-cloud-upload-alt action-icon upload"></i>
			</div>
			<button @click="updateContactMeLine" class="btn btn-success">Save instance</button>
		</div>
		<div class="contact-me">
			<textarea 
				type="text"
				rows="5"
				ref="ediatbleContactMeLine"
				name="ediatbleContactMeLine"
				id="ediatbleContactMeLine"
				v-if="ediatbleContactMeLine.edit"
				v-model="ediatbleContactMeLine.line"
				@keyup.enter="ediatbleContactMeLine.edit = false"
				@blur="ediatbleContactMeLine.edit = false"></textarea>
			<p
				v-else
				class="about"
				@click="updateEditStatus">{{ ediatbleContactMeLine.line == '' ? 'Click to edit' : ediatbleContactMeLine.line }}</p>
			<a :href="fileURL" target="_blank" class="resume-wrapper">
				<i class="fas fa-address-card">
					<p class="resume">Resume</p>
				</i>
			</a>
		</div>
	</div>
</template>

<script>
import firebase from 'firebase/compat';
import { mapActions } from 'vuex';

export default {
	name: 'EditContactMe',
	data() {
		return {
			ediatbleContactMeLine: {
				line: this.$store.state.contactMeLine,
				edit: false
			},
			fileName: 'Upload file',
			fileToUpload: {},
			fileURL: '',
			prevName: this.$store.state.fileName
		}
	},
	methods: {
		...mapActions({
			saveContactMeLine: 'updateContactMeLine',
			saveFileName: 'updateFileName',
		}),
		updateContactMeLine() {
			this.saveContactMeLine(this.ediatbleContactMeLine.line);
			this.$emit('notification', 'Changes saved locally!');
		},
		updateEditStatus() {
			this.ediatbleContactMeLine.edit = true;
			setTimeout(() => {
				this.$refs.ediatbleContactMeLine.focus();
			},100)
		},
		onFileChange(event) {
			this.fileToUpload = event.target.files[0];
			this.fileURL = URL.createObjectURL(this.fileToUpload);
			this.fileName = this.fileToUpload.name;
		},
		async uploadFile(file) {
			if(confirm('This action will replace your existing file and is irreversible. Do you wish to continue?')) {
				await firebase.storage().ref('/'+ this.prevName).delete()
				.then(() => {
					this.$emit('notification', 'Existing file (' + this.prevName + ') deleted!', 'delete');
					firebase.storage().ref('/' + file.name).put(file)
					.then(() => {
						this.saveFileName(file.name);
						// change file name in realtime database
						const projectsRef = firebase.database().ref('portfolio');
						projectsRef.update({
							'fileName': file.name
						});
						this.$emit('notification', file.name + ' uploaded to firebase!', 'firebase');
						this.loadData(file.name);
					}).catch(err => console.log(err));
				}).catch(err => console.log(err));
			}
		},
		async loadData(name=this.prevName) {
			const ref = firebase.storage().ref().child(name);
			const url = await ref.getDownloadURL()
			this.fileURL = url;
			this.prevName = name;
		}
	},
	mounted() {
		this.loadData();
	}
}
</script>

<style lang="scss" scoped>
$color: aquamarine;

.change-resume {
	padding: 5rem 
}

.action-buttons {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	grid-gap: 1rem;
	margin-bottom: 2rem;
}

.about {
	cursor: pointer;
}

* {
	color: white;
}
.fas {
	font-size: 3rem;
	transition: all 100ms ease-in-out;
	padding: 20px;
}
.resume {
	display: inline-block;
	font-family: 'Tajawal', sans-serif;
	font-size: 50%;
	margin: 13px 20px;
	vertical-align: bottom;
}
.resume-wrapper {
	cursor: pointer;
}
.fas:hover {
	color: $color;
	transform: scale(1.1);
	transition: all 100ms ease-in-out;
	.resume {
		color: $color;
	}
}

textarea {
	border-radius: 8px;
	padding: 0;
	vertical-align: middle;
	border: none;
	background: none !important;
	opacity: 0.9;
	width: 100%;
	color: white;
	margin-bottom: 1rem;
	text-align: center;
}
textarea:focus {
	background-color: #ddd;
	outline: none;
}

input[type=file] {
	display: none;
}
.custom-file-upload {
	display: flex;
	flex-direction: column;
	padding: 0.5rem;
	cursor: pointer;
	width: max-content;
	margin: auto;
	background-color: #353cb4;
	color: white;
	border-radius: 0.3rem;
}
.custom-file-upload:hover {
	background-color: #2d3385;
}
.file {
	display: flex;
	justify-content: center;
	align-items: center;
	grid-gap: 0.5rem;
	flex-wrap: wrap;
}

.action-icon {
	cursor: pointer;
	padding: 0.77rem;
    border-radius: 0.3rem;
}
.upload {
    background-color: #353cb4;
	&:hover {
		background-color: #2d3385;
	}
}
.cancel {
	background-color: #b90000;
	&:hover {
		background-color: darkred;
	}
}
</style>